<template>
  <div class="promo-code-promotions">
    <div class="row">
      <div class="top-row w-100">
        <div
          class="create-new-btn shadow-sm"
          v-if="activeTab === 0 || activeTab === 2"
          @click="CreateNew"
        >
          Create New
        </div>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Promotions
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            Archived
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 2 ? 'active' : ''"
            @click="changeTab(2)"
          >
            Promo Codes
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 3 ? 'active' : ''"
            @click="changeTab(3)"
          >
            Archived
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab active>
        <ActivePromotions />
      </b-tab>
      <b-tab>
        <ArchivedPromotions />
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 2">
          <PromoCodes ref="promoCodesRef" />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 3">
          <archived-promo-codes ref="archivedPromoCodesRef" />
        </template>
      </b-tab>
    </b-tabs>
    <template v-if="createPromotion">
      <NewPromotion ref="newPromotionRef" @close="closeCreatePromotion" />
    </template>
    <template v-if="createPromo">
      <NewPromoCode
        ref="newPromoCodeRef"
        @close="closeCreatePromo"
        @created="createdPromo"
      />
    </template>
  </div>
</template>

<script>
import ActivePromotions from "@/view/pages/promotions/ActivePromotions";
import ArchivedPromotions from "@/view/pages/promotions/ArchivedPromotions";
import PromoCodes from "@/view/pages/promotions/PromoCodes";
import ArchivedPromoCodes from "@/view/pages/promotions/ArchivedPromoCodes";
import NewPromotion from "@/view/pages/promotions/NewPromotion";
import NewPromoCode from "@/view/pages/promotions/NewPromoCode";
export default {
  components: {
    ActivePromotions,
    ArchivedPromotions,
    PromoCodes,
    NewPromotion,
    NewPromoCode,
    "archived-promo-codes": ArchivedPromoCodes
  },
  data() {
    return {
      activeTab: 0,
      createPromo: false,
      createPromotion: false
    };
  },
  methods: {
    changeTab: function(index) {
      this.activeTab = index;
    },
    closeCreatePromo: function() {
      setTimeout(() => {
        this.createPromo = false;
      }, 100);
    },
    closeCreatePromotion: function() {
      setTimeout(() => {
        this.createPromotion = false;
      }, 100);
    },
    createdPromo: function() {
      this.closeCreatePromo();
      this.$refs.promoCodesRef.updatePromos();
    },
    CreateNew: function() {
      if (this.activeTab === 0) {
        this.createPromotion = true;
        setTimeout(() => {
          this.$refs.newPromotionRef.showModal();
        }, 100);
      } else {
        this.createPromo = true;

        setTimeout(() => {
          this.$refs.newPromoCodeRef.showModal();
        }, 100);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.promo-code-promotions {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;

    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
