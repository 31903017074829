<template>
  <div class="single-promo shadow-sm">
    <div class="upper-block">
      <div class="promo-block shadow-sm">
        <div class="promo-text">
          <div class="promo-heading">{{ promo.code }}</div>
        </div>
      </div>
    </div>
    <div class="lower-block">
      <div class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Promotion Type</div>
            <div class="info-value">{{ promo.promoType }}</div>
          </div>
        </div>
        <template v-if="promo.promoType !== 'freeDelivery'">
          <div class="col-md-4">
            <div class="info-block">
              <div class="info-head">Promo Discount</div>
              <div class="info-value">
                {{ promo.promoType !== "Percentage" ? "$" : ""
                }}{{ promo.promoDiscount
                }}{{ promo.promoType === "Percentage" ? "%" : "" }}
              </div>
            </div>
          </div>
        </template>
        <div
          :class="promo.promoType === 'freeDelivery' ? 'offset-4' : ''"
          class="col-md-4 d-flex justify-content-center"
        >
          <span
            v-bind:class="!promo.archived ? 'archive' : 'unarchive'"
            @click="archiveAction"
            class="action-btn"
            >{{ promo.archived ? "Unarchive" : "Archive" }}</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Start Date</div>
            <div class="info-value">{{ promo.startDate }}</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">End Date</div>
            <div class="info-value">{{ promo.expiryDate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {};
  },
  mounted() {
    this.promo.expiryDate = this.timeToText(this.promo.expiryDate);
    this.promo.startDate = this.timeToText(this.promo.startDate);
  },
  props: {
    promo: Object
  },
  methods: {
    timeToText: function(date) {
      const newDate = parseInt(date);
      return moment(newDate).format("MMMM Do YYYY");
    },
    archiveAction: async function() {
      const data = { id: this.promo._id };
      await ApiService.post(
        `/promoCode/archive/${!this.promo.archived ? "archive" : "unarchive"}`,
        data
      )
        .then((/* { data: response } */) => {
          this.$emit("update");
        })
        .catch((/* { data: response } */) => {
          this.$emit("update");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.single-promo {
  width: 525px;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  position: relative;
  margin: 10px;

  .upper-block {
    .promo-block {
      border-radius: 0 0 10px 10px;
      height: 200px;
      background: #e72a37;
      display: flex;
      align-items: flex-start;
      .promo-text {
        padding: 15px;
        display: flex;
        flex-direction: column;
        .promo-heading {
          font-size: 40px;
          line-height: 0.75;
          color: #fff;
        }
        .promo-name {
          font-size: 40px;
          color: #fff;
        }
      }
    }
  }

  .lower-block {
    padding: 20px;
    .row {
      margin-bottom: 10px;
    }
    .info-block {
      display: flex;
      flex-direction: column;
      .info-head {
        font-weight: 600;
      }
      .info-value {
        color: #bebdbd;
      }
    }
    .action-btn {
      color: #fff;
      cursor: pointer;
      padding: 8px 25px;
      width: 100%;
      text-align: center;
      &.unarchive {
        background: #50a6fa;
      }
      &.archive {
        background: #f8b040;
      }
    }
  }
}
</style>
