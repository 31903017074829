<template>
  <div>
    <b-modal
      id="new-promo-code-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-promo-code-modal"
      @hide="hideModal"
    >
      <span class="close-btn shadow-sm" @click="hideModal">
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Promo Code</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit.prevent="createNewPromo">
        <div class="input-row row">
          <div class="col-md-6">
            <b-form-datepicker
              id="start-date-datepicker"
              v-model="form.startDateValue"
              :min="dateNow"
              :max="form.expiryDateValue"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              class="mb-2"
            ></b-form-datepicker>
          </div>
          <div class="col-md-6">
            <b-form-datepicker
              id="end-date-datepicker"
              v-model="form.expiryDateValue"
              :min="form.startDateValue"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="input-row">
          <span class="bold-label">Promo Code Type</span>
          <b-form-radio-group
            id="promotion-type"
            v-model="form.promoType"
            :options="promoOptions"
            name="promotion-type"
          ></b-form-radio-group>
        </div>
        <template v-if="form.promoType !== 'freeDelivery'">
          <div class="input-row row">
            <div class="col-12">
              <b-input type="number" v-model="form.promoDiscount" required />
            </div>
          </div>
        </template>
        <div class="input-row row">
          <div class="col-12">
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
              required
            ></b-form-textarea>
          </div>
        </div>

        <div class="input-row center">
          <b-button
            type="Submit"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            class="create-button"
            >Submit</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";
export default {
  data() {
    return {
      form: {
        name: "",
        promoType: "flat",
        startDateValue: "",
        startDate: 0,
        expiryDate: 0,
        expiryDateValue: "",
        description: "",
        promoDiscount: 0
      },
      hasError: false,
      processingError: "",
      processingRequest: "",
      dateNow: "",
      promoOptions: [
        { text: "Flat", value: "flat" },
        { text: "Free Delivery", value: "freeDelivery" },
        { text: "Percentage", value: "percentage" }
      ]
    };
  },
  mounted() {
    const now = Date.now();
    this.dateNow = this.timeToText(now);
    this.form.startDateValue = this.dateNow;
    this.form.expiryDateValue = this.dateNow;
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("new-promo-code-modal");
    },
    hideModal: function() {
      this.$bvModal.hide("new-promo-code-modal");
      this.$emit("close");
    },
    timeToText: function(date) {
      const newDate = parseInt(date);
      return moment(newDate).format("YYYY-MM-D");
    },
    textToMilli: function(text) {
      return moment(text, "YYYY-MM-D", false).format("x");
    },
    createNewPromo: function() {
      this.hasError = "d-none";
      this.processingError = "";
      this.processingRequest = "processing";
      this.form.startDate = this.textToMilli(this.form.startDateValue);
      this.form.expiryDate = this.textToMilli(this.form.expiryDateValue);
      ApiService.post("/promoCode/create", this.form).then(({ data: res }) => {
        console.log("ApiService.post | res", res);
        if (res.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.message;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
          return;
        }

        this.processingRequest = "";
        this.$bvModal.hide("new-promo-code-modal");
        this.$emit("created");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .new-promo-code-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }

      .image-upload {
        label {
          display: flex;
          align-items: center;

          span.icon {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 14px;
              color: #000;
              padding: 0 10px;
            }
            padding: 8px 5px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
      .bv-no-focus-ring {
        display: flex;
        justify-content: space-around;
      }
      .bold-label {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
      #start-date-datepicker__value_,
      #end-date-datepicker__value_ {
        font-size: 12px;
      }
    }
  }
}
</style>
