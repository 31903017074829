<template>
  <div>
    <b-modal
      id="new-promotion-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-promotion-modal"
      @hide="hideModal"
    >
      <span class="close-btn shadow-sm" @click="hideModal">
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Promotion</h3>
      <form>
        <div class="input-row row">
          <div class="col-md-6">
            <b-form-input
              required
              v-model="form.name"
              placeholder="Promotion Name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <div class="image-upload">
              <label for="rider-iamge-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                  <span class="text">Upload Image</span>
                </span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="levelImage"
                id="levelImage-image-input"
                class="mt-3"
                ref="levelImageRef"
              />
            </div>
          </div>
        </div>
        <div class="input-row">
          <span class="bold-label">Promotion Type</span>
          <b-form-radio-group
            id="promotion-type"
            v-model="form.promotionType"
            :options="promotionOptions"
            name="promotion-type"
          ></b-form-radio-group>
        </div>
        <div class="input-row">
          <span class="bold-label">Promotion Placement</span>
          <b-form-radio-group
            id="promotion-type"
            v-model="form.promotionPlacement"
            :options="promotionPlacementOptions"
            name="promotion-type"
          ></b-form-radio-group>
        </div>
        <!-- Descriptions -->
        <div class="input-row row">
          <div class="col-md-4">
            <b-form-input
              required
              v-model="form.promotionDiscount"
              placeholder="Promotion Discount %"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <b-form-datepicker
              id="start-date-datepicker"
              v-model="form.startDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              class="mb-2"
            ></b-form-datepicker>
          </div>
          <div class="col-md-4">
            <b-form-datepicker
              id="end-date-datepicker"
              v-model="form.endDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="input-row row">
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder="Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>

        <div class="input-row center">
          <b-button type="Submit" class="create-button">Submit</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        level: "",
        shortDescription: "",
        promotionType: "sub-category",
        promotionPlacement: "top",
        startDate: "",
        endDate: "",
        description: ""
      },
      promotionOptions: [
        { text: "Sub Category", value: "sub-category" },
        { text: "Product", value: "Product" }
      ],
      promotionPlacementOptions: [
        { text: "Top", value: "top" },
        { text: "Middle", value: "mid" },
        { text: "Bottom", value: "bot" }
      ]
    };
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("new-promotion-modal");
    },
    hideModal: function() {
      this.$bvModal.hide("new-promo-code-modal");
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .new-promotion-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }
      .image-upload {
        label {
          display: flex;
          align-items: center;

          span.icon {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 14px;
              color: #000;
              padding: 0 10px;
            }
            padding: 8px 5px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
      .bv-no-focus-ring {
        display: flex;
        justify-content: space-around;
      }
      .bold-label {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
      #start-date-datepicker__value_,
      #end-date-datepicker__value_ {
        font-size: 12px;
      }
    }
  }
}
</style>
